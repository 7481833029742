<template>
	<div class="voiceAnalyze">
		<el-dialog
			title="预警词组选择"
			:visible.sync="dialogVisible"
			width="35%"
		>
			<el-row class="selectAll">
				<el-col :span="4">
					<span style="line-height: 0.4rem">词组选择：</span>
				</el-col>
				<el-col :span="20">
					<el-select v-model="wordContent" placeholder="请选择">
						<el-option
							v-for="item in keywordAll"
							:key="item.id"
							:label="item.content"
							:value="item.id"
							@click.native="handleKeyword(item.id)"
						>
						</el-option>
					</el-select>
				</el-col>
			</el-row>
			<el-row style="text-align: left; margin-bottom: 0.1rem"
				><span>预警词：</span></el-row
			>
			<el-row class="keyContent">
				<el-scrollbar
					style="height: 100%"
					wrap-style="overflow-x:hidden;"
				>
					<el-tag
						v-for="tag in keywordList"
						:key="tag.id"
						:disable-transitions="false"
						>{{ tag.content }}</el-tag
					>
				</el-scrollbar>
			</el-row>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button class="blueBtn" type="primary" @click="applyWord"
					>应用</el-button
				>
			</span>
		</el-dialog>
		<div class="content">
			<div class="title">异常声音分析</div>
			<div class="soundWave">
				<div class="currentWarning">
					<p v-show="isShow" v-html="currentWarning"></p>
				</div>
				<!-- <div class="waveWarp">
          <div
            class="WaveSurferViewDemo"
            style="display: inline-block;vertical-align: bottom;width: 100%;height: 300px;"
            v-show="taskStatus === 1"
          ></div>

          <div
            class="WaveSurferRecord"
            style="display: inline-block;vertical-align: bottom;width: 100%;height: 300px;"
            v-show="taskStatus === 2"
          >
            <div id="waveform"></div>
            <div id="timeline"></div>
          </div>

          <div class="audioWarp">
            <audio id="recordAudio" preload="auto">
              <source src type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
          </div>
        </div> -->
			</div>
			<div class="center">
				<div class="center-left">
					<div class="warningTitle">预警列表</div>
					<div class="warningList" ref="warningList">
						<div
							class="warningItem"
							v-for="(warning, index) in warningList"
							:key="index"
							@click="seekTo(warning, true)"
						>
							<!-- <span class="warningTime">{{
                warning.beginTime | dateLengthFormat
              }}</span> -->
							<p v-html="warning.text"></p>
						</div>
					</div>
				</div>
				<div class="center-right">
					<!-- 实时文字列表 -->
					<div class="audioList" ref="audioList">
						<p
							class="warningItem"
							@click="seekTo(audio, true)"
							v-for="(audio, index) in audioList"
							:key="index"
							v-html="audio.text"
						></p>
					</div>
					<el-button class="dialog" type="text" @click="clickVisible"
						>预警词组选择</el-button
					>
					<p class="tip">
						<span>注：“</span>
						<span style="color: #ff6d6d">红色</span>
						<span>”代表预警词，“</span
						><span style="color: #facc70">黄色</span>
						<span>”代表声音过大</span>
					</p>
					<el-tooltip content="继续" placement="top" v-if="!isPause">
						<i
							class="iconfont icon-kaishi"
							@click="handPause"
							v-show="isPlay"
						></i>
					</el-tooltip>
					<el-tooltip content="暂停" placement="top" v-else>
						<i
							class="iconfont icon-zanting"
							@click="handPause"
							v-show="isPlay"
						></i>
					</el-tooltip>
					<el-button
						class="start blueBtn"
						v-if="!isPlay"
						@click="handPaly"
						>开始</el-button
					>
					<el-button
						class="stop"
						type="danger"
						:disabled="!isPause"
						v-else
						@click="stopRecord"
						>停止</el-button
					>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
const SAMPLE_RATE = 16000 // 采样率
// import PCMPlayer from "pcm-player";
export default {
	name: 'voiceAnalyze',
	data() {
		return {
			wordContent: '', //默认选中的词组
			parentId: '', //选中的词组id
			inputValue: '',
			keywordAll: [],
			keywordList: [],
			isPause: false,
			dialogVisible: false,
			socketUrl: null,
			WaveSurferViewDemo: null,
			deviceId: '',
			devices: null,
			isPlay: false,
			player: null,
			taskId: '',
			socketConnect: null,
			mp3SocketConnect: null,
			warningList: [],
			currentWarning: '',
			audioList: [],
			endCurrent: '',
			waveSurfer: null,
			waveUrl: '',
			waveTime: 1,
			videoUrl: '',
			waveInterval: null,
			bloomTime: '',
			currentTime: '',
			setData: null,
			scrollFlag: true,
			scrollFlag1: true,
			getMsgStatus: false,

			taskStatus: 1,
			rtspUrl: window.rtspUrl,
			videoInterval: null,
			videoInterval2: null,
			videoPlayer: null,
			videoPlayer2: null,
			showVideo: false,
			closeTime: 0,
			index1: 0,
			bufferData: null,
			timer: null,
			isShow: true,
			rec: null,
			socketData: null,
			sendTryTime: 0,
			realTimeSendTryChunk: null,
		}
	},
	created() {
		//创建时执行跳转锚点位置
		this.$nextTick(() => {
			this.getlocal()
		})
	},
	mounted() {},
	watch: {
		bufferData() {
			if (this.isPlay) {
				this.player.feed(this.bufferData)
			}
		},
	},
	methods: {
		getlocal() {
			//找到锚点id
			let toElement = document.getElementById('experienceId')
			toElement.scrollIntoView()
		},
		clickVisible() {
			this.dialogVisible = true
			this.getKeywordAll()
		},
		getKeywordAll() {
			this.axios
				.request({
					url: window.voiceUrl + '/wechatWarnWord/selectAll',
					method: 'post',
					data: {
						pageNum: 1,
						pageRows: 20,
					},
				})
				.then((res) => {
					if (res.data.code === 200) {
						this.keywordAll = res.data.data
						this.keywordAll.forEach((item) => {
							if (item.isUse) {
								this.wordContent = item.content
								this.handleKeyword(item.id)
							}
						})
					} else {
						this.$message.warning(res.data.msg)
					}
				})
				.catch(() => {})
		},
		handleKeyword(parentId) {
			this.parentId = parentId
			this.axios
				.request({
					url: window.voiceUrl + '/wechatWarnWord/selectByParentId',
					method: 'post',
					data: {
						pageNum: 1,
						pageRows: 20,
						parentId,
					},
				})
				.then((res) => {
					if (res.data.code === 200) {
						this.keywordList = res.data.data
					} else {
						this.$message.warning(res.data.msg)
					}
				})
				.catch(() => {})
		},
		applyWord() {
			this.dialogVisible = false
			this.axios
				.request({
					url: window.voiceUrl + '/wechatWarnWord/updateWarnWord',
					method: 'post',
					data: {
						pageNum: 1,
						pageRows: 20,
						parentId: this.parentId,
					},
				})
				.then((res) => {
					if (res.data.code === 200) {
						this.$message.success(res.data.msg)
					} else {
						this.$message.warning(res.data.msg)
					}
				})
				.catch(() => {})
		},
		handPause() {
			this.isPause = !this.isPause
			this.isPause ? this.rec.resume() : this.rec.pause()
		},
		handPaly() {
			this.getTaskId() //先获取任务id
			this.isPause = true
			this.isPlay = true
		},
		// loadWave() {
		//   this.player = new PCMPlayer({
		//     //创建wen段PCM裸流播放
		//     encoding: "16bitInt", //16bit数据pcm的范围是-32768~32767
		//     channels: 1,
		//     sampleRate: 16000,
		//     flushingTime: 2000,
		//   });

		//   // let { pcmSocketPort, asrIp } =
		//   //   this.socketUrl || new Error("未获取到WS地址");
		//   // let url =
		//   //   "ws://" +
		//   //   asrIp +
		//   //   ":" +
		//   //   pcmSocketPort +
		//   //   "/audioStream" +
		//   //   "?taskId=" +
		//   //   this.taskId;
		//   let url =
		//     "ws://" +
		//     "jet-voice.com/aliyun-warn/pcmReceiver" +
		//     "?taskId=" +
		//     "${this.taskId}" +
		//     "&systemType=true";
		//   this.mp3SocketConnect = new WebSocket(url); //创建一个websocket通信
		//   this.mp3SocketConnect.binaryType = "arraybuffer"; //将客户端的传输方式改为arraybuffer
		//   let that = this;
		//   this.mp3SocketConnect.addEventListener("message", function(event) {
		//     that.bufferData = event.data;
		//     let data = new Int16Array(event.data);
		//     that.WaveSurferViewDemo.input(data, 20, 16000);
		//   });
		// },
		initScroll() {
			// 实时文字列表滚动
			this.$refs.audioList.onscroll = () => {
				let div = this.$refs.audioList
				this.scrollFlag = false
				let sh = div.scrollHeight //文档内容实际高度，包括超出视窗的溢出部分
				let st = div.scrollTop //滚动条滚动距离
				let ch = div.clientHeight //窗口可视范围高度
				this.$nextTick(() => {
					if (sh - ch <= st + 1) {
						this.scrollFlag = true
					}
				})
			}

			// 实时告警列表滚动
			this.$refs.warningList.onscroll = () => {
				let div = this.$refs.warningList
				this.scrollFlag1 = false
				let sh = div.scrollHeight
				let st = div.scrollTop
				let ch = div.clientHeight
				this.$nextTick(() => {
					if (sh - ch <= st + 1) {
						this.scrollFlag1 = true
					}
				})
			}
		},
		scroll() {
			if (this.scrollFlag) {
				let div = this.$refs.audioList
				this.$nextTick(() => {
					div.scrollTop = div.scrollHeight
				})
				// this.isOver(100);
			}
			if (this.scrollFlag1) {
				let div = this.$refs.warningList
				this.$nextTick(() => {
					div.scrollTop = div.scrollHeight
				})
			}
		},

		// 初始化波形
		// startWave() {
		//   // this.stopWave()
		//   this.WaveSurferViewDemo = new window.WaveSurferView({
		//     elem: ".WaveSurferViewDemo",
		//     scale: 2,
		//     fps: 50,
		//     duration: 2500,
		//     direction: -1,
		//     position: 0,
		//     linear: [0, "#0D4CA2", 0.7, "#0D4CA2", 1, "#0D4CA2"],
		//   });
		//   this.loadWave();
		// },

		// 停止波形
		// stopWave() {
		//   this.WaveSurferViewDemo = null;
		// },

		// 获取taskId
		getTaskId() {
			this.axios
				.request({
					url: window.voiceUrl + '/warnAnalysis/startAnalysis',
					method: 'post',
				})
				.then((res) => {
					if (res.data.code === 200) {
						this.taskId = res.data.data.taskId
						this.openSocket() //建立 Websocket连接
					}
				})
				.catch(() => {
					new Error('获取taskId错误!')
				})
		},
		// 重置页面
		resetPage() {
			this.audioList = []
			this.warningList = []
			this.currentWarning = ''
			if (this.taskStatus === 2) {
				// this.videoPlayer.pause()
				this.stopWaveSurferRecord()
			}
		},
		// 录音请求
		startRecord() {
			this.rec = this.Recorder({
				type: 'unknown',
				onProcess: (buffers) => {
					this.streamSend(buffers)
				},
			})

			let t = setTimeout(function () {
				console.Log(
					'无法录音：权限请求被忽略（超时假装手动点击了确认对话框）'
				)
			}, 8000)

			/**调用open打开录音请求好录音权限**/
			this.rec.open(
				() => {
					//打开麦克风授权获得相关资源
					clearTimeout(t)
					this.rec.start() //开始录音
				},
				function (msg, isUserNotAllow) {
					clearTimeout(t)
					console.Log(
						(isUserNotAllow ? 'UserNotAllow，' : '') +
							'无法录音:' +
							msg
					)
				}
			)
		},

		streamSend(buffer) {
			const time = Date.now()
			if (this.sendTryTime == 0) {
				this.sendTryTime = time
				this.realTimeSendTryChunk = null
			}
			if (time - this.sendTryTime < window.SendInterval) return
			this.sendTryTime = time
			var chunk = this.Recorder.SampleData(
				buffer,
				this.rec.srcSampleRate,
				SAMPLE_RATE,
				this.realTimeSendTryChunk
			)
			for (
				let i = this.realTimeSendTryChunk
					? this.realTimeSendTryChunk.index
					: 0;
				i < chunk.index;
				i++
			) {
				this.rec.buffers[i] = null
			}
			this.realTimeSendTryChunk = chunk

			if (chunk.data.length == 0 || chunk.data.length < 640) {
				console.log('so short')
			} else {
				this.socketConnect.send(chunk.data)
			}
		},

		// 点击结束按钮
		stopRecord() {
			// this.isPause = true;
			this.isPlay = false
			this.rec.close()
			this.closeSocket()
			if (!this.taskId) return
			let data = new FormData()
			data.append('taskId', this.taskId)
			this.axios
				.request({
					url: window.voiceUrl + '/warnAnalysis/stopAnalysis',
					method: 'post',
					data,
				})
				.then((res) => {
					if (res.data.code === 200) {
						// const waveUrl = window.jetUrl + "mp3/" + this.taskId + ".mp3";
						// this.waveUrl = waveUrl;
						this.taskId = ''
						// this.taskStatus = 2;
						// this.stopWave();
						// this.WaveSurferRecord.load(this.waveUrl);
						// // this.initWaveSurferRecord()
						// this.initAudio();
					} else {
						this.$message.warning(res.data.msg)
					}
				})
				.catch(() => {})
		},

		openSocket() {
			// let url =
			//   "wss://" +
			//   "jet-voice.com/aliyun-warn" +
			//   "?taskId=" +
			//   this.taskId +
			//   "&systemType=true";
			let url =
				'wss://' +
				'jet-voice.com/aliyun-warn' +
				'?taskId=' +
				this.taskId +
				'&systemType=true&systemPc=PC'
			this.socketConnect = new WebSocket(url)

			this.socketConnect.addEventListener('message', (event) => {
				//用于监听获取服务端（后端）发送过来的数据,(标识，后端返回的数据)
				let source = JSON.parse(event.data)

				let temp = {
					beginTime: source.beginTime,
					keywords: source.keywords,
					text: source.text,
					isEnd: source.isEnd,
					isNoise: source.isNoise,
					isWhisper: source.isWhisper,
				}

				if (this.timer !== null) {
					clearTimeout(this.timer)
					this.timer = null
					this.currentWarning = ''
					this.isShow = true
				}

				if (source.isEnd) {
					if (source.isNoise) {
						temp.text = temp.text.replace(
							temp.text,
							`<span style="color: #E6A23C;">${temp.text}</span>`
						)
						this.warningList.push(temp) //添加告警列表信息

						this.editWarning(source) //后端返回信息，顶部提醒告警信息异常
					} else if (temp.keywords && temp.keywords.length !== 0) {
						// temp.text = temp.text.replace(
						//   temp.text,
						//   `<span style="color: #E6A23C;">${temp.text}</span>`
						// );
						this.warningList.push(temp) //添加告警列表信息

						this.editWarning(source) //后端返回信息，顶部提醒告警信息异常
					} else if (source.isWhisper) {
						this.editWarning(source) //后端返回信息，顶部提醒告警信息异常
					} else {
						this.currentWarning = ''
					}
					if (temp.keywords && temp.keywords.length !== 0) {
						let keyArray = source.keywords,
							arr = []
						for (let i = 0; i < keyArray.length; i++) {
							if (arr.indexOf(keyArray[i].key) === -1) {
								temp.text = temp.text.replace(
									new RegExp(keyArray[i].key, 'g'),
									`<span style="color: red"><span style="color: #F56C6C;">${keyArray[i].key}</span></span>`
								)
							}
							arr.push(keyArray[i].key)
						}
					}
				}
				let len = this.audioList.length //当前实时文字列表数组长度
				if (this.endCurrent.isEnd || len < 1) {
					//audioList数组无数据时
					this.audioList.push(temp) //添加后端返回的数据
				} else {
					//反之，数据按序拼接
					this.audioList[len - 1].isEnd = temp.isEnd
					this.audioList[len - 1].text = temp.text
					this.audioList[len - 1].beginTime = temp.beginTime
					this.audioList[len - 1].keywords = temp.keywords
					this.audioList[len - 1].isNoise = temp.isNoise
				}
				this.endCurrent = temp
				this.scroll()
			})

			this.socketConnect.addEventListener('open', () => {
				console.log('连接成功')
				this.startRecord() // 开始录音
			})
			this.socketConnect.addEventListener('close', () => {
				console.log('关闭连接')
			})
			// this.socketConnect.on("connect", () => {
			//   this.startWave();
			//   console.log("client connect serve," + new Date());
			// });
		},
		closeSocket() {
			//结束通讯
			if (this.socketConnect) {
				this.socketConnect.close()
			}
		},
		// initWaveSurferRecord() {
		//   this.WaveSurferRecord = this.WaveSurfer.create({
		//     //创建波形，记录
		//     container: "#waveform",
		//     waveColor: "#5d86ff",
		//     progressColor: "#0D4CA2",
		//     barHeight: 1.5,
		//     barWidth: 1,
		//     height: 300,
		//     cursorColor: "#0D4CA2",
		//     scrollParent: false,
		//     hideScrollbar: false,
		//     plugins: [],
		//   });

		//   this.WaveSurferRecord.on("loading", () => {
		//     this.loading = true;
		//   });
		//   this.WaveSurferRecord.on("ready", () => {
		//     document.getElementById("waveform").onclick = () => {
		//       //点击波形触发
		//       setTimeout(() => {
		//         this.seekSameCurrent(this.WaveSurferRecord.getCurrentTime(), true);
		//       }, 200);
		//     };
		//   });
		//   this.WaveSurferRecord.on("play ", () => {
		//     console.log("play", this.WaveSurferRecord.getCurrentTime());
		//   });
		//   this.WaveSurferRecord.on("finish", () => {
		//     this.stopWaveSurferRecord(); //监听，已完成，记录波形结束
		//   });
		// },
		// pauseWaveSurferRecord() {
		//   this.WaveSurferRecord.pause(); //波形记录暂停
		// },
		// stopWaveSurferRecord() {
		//   this.pauseWaveSurferRecord();
		//   this.WaveSurferRecord.stop();
		// },
		// initAudio() {
		//   if (!this.waveUrl) return;

		//   this.waveSurfer = document.getElementById("recordAudio"); //选择audio标签
		//   this.waveSurfer.src = this.waveUrl + "?" + new Date().getTime();

		//   this.waveSurfer.addEventListener("error", () => {
		//     setTimeout(() => {
		//       if (this.waveTime > 20) {
		//         // this.$message.warning(record.audioError);
		//         return;
		//       } else {
		//         this.waveTime++;
		//         this.waveSurfer.load(this.waveUrl); //加载音频文件（注意跨域问题）
		//       }
		//     }, 500);
		//   });
		//   this.waveSurfer.addEventListener("ended", () => {
		//     // this.wavePlay = false;
		//     this.clearBloom();
		//     // 清除定时器，停止读秒
		//     clearInterval(this.waveInterval);
		//   });
		// },
		// clearBloom() {
		//   let content = document.querySelector(".audioList");
		//   content.querySelectorAll(".text-bloom").forEach((item) => {
		//     item.classList.remove("text-bloom");
		//   });
		// },
		// setEditorBloom(time) {
		//   //文字列表，数据编辑
		//   let content = document.querySelector(".audioList"),
		//     items = content.querySelectorAll(".warningItem[name]"),
		//     tempTime,
		//     itemList = [];
		//   if (this.bloomTime !== time) {
		//     items.forEach((item) => {
		//       let name = parseInt(item.getAttribute("name"));
		//       if (!isNaN(name)) {
		//         let val =
		//           Math.floor(name / 1000) == 0 ? 1 : Math.floor(name / 1000);
		//         if (val === time) {
		//           tempTime = time;
		//           itemList.push(item);
		//         }
		//       }
		//     });
		//   }
		//   if (tempTime) {
		//     this.bloomTime = tempTime;
		//     content.querySelectorAll(".text-bloom").forEach((item) => {
		//       item.classList.remove("text-bloom");
		//     });
		//     itemList.forEach((item) => {
		//       item.classList.add("text-bloom");
		//     });
		//   }
		// },
		// setCurrent(data) {
		//   this.currentTime = this.formatSeconds(data);
		//   this.waveSurfer.play(); //开始计时
		// },
		// formatSeconds(t) {
		//   //时间长度处理，显示
		//   let nowH = parseInt(t / 3600),
		//     nowM = parseInt((t % 3600) / 60),
		//     nowS = parseInt(t % 60);
		//   nowH = nowH < 10 ? "0" + nowH : nowH;
		//   nowM = nowM < 10 ? "0" + nowM : nowM;
		//   nowS = nowS < 10 ? "0" + nowS : nowS;
		//   return nowH + ":" + nowM + ":" + nowS;
		// },

		// 顶部提醒
		editWarning(val) {
			if (val.keywords && val.keywords.length !== 0) {
				let keyArray = val.keywords,
					arr = []
				for (let i = 0; i < keyArray.length; i++) {
					arr.push(keyArray[i].key)
				}
				this.currentWarning = '关键词告警：' + arr.join('，')
			} else if (val.isNoise) {
				this.currentWarning = '声音异常告警：声音过高'
			} else if (val.isWhisper) {
				this.currentWarning = '声音异常告警：窃窃私语中'
			} else {
				this.currentWarning = ''
			}
			this.timer = setTimeout(() => {
				this.isShow = false
			}, window.duration)
		},
		seekTo(val, second) {
			let beginTime = val.beginTime
			if (beginTime === 0) {
				beginTime = 1
			}
			// this.editWarning(val); //顶部提醒异常
			if (!beginTime || this.WaveSurferViewDemo) return
			if (second) {
				beginTime = beginTime / 1000
			}
			this.seekSameCurrent(beginTime)
		},
		seekSameCurrent(time) {
			if (this.WaveSurferRecord) {
				// this.waveSurfer.currentTime = time;
				let temp = time - this.WaveSurferRecord.getCurrentTime()
				this.WaveSurferRecord.skip(temp)
				this.WaveSurferRecord.play() //波形记录播放

				// this.setCurrent(time);
			}
			if (this.videoPlayer) {
				this.seekToVideo(time)
			}
		},
		seekToVideo(val) {
			if (this.videoPlayer) {
				val = parseFloat(val)
				this.videoPlayer.currentTime = val
				this.videoPlayer.play() //从当前时间记录播放
			}
		},
	},
	destroyed() {
		// this.stopWave()
		// this.destroyVideo();
		if (this.rec) this.stopRecord()
	},
}
</script>
<style lang="scss" scoped>
.voiceAnalyze {
	.content {
		width: 100%;
		.title {
			font-size: 0.28rem;
			font-family: Source Han Sans, Source Han Sans CN;
			font-weight: 700;
			color: #127bf2;
			line-height: 0.36rem;
			margin-bottom: 0.2rem;
		}
		.soundWave {
			width: 100%;
			height: 0.8rem;
			background-color: #eceff3;
			opacity: 1;
			border-radius: 0.1rem;
			.currentWarning {
				width: inherit;
				height: 0.8rem;
				p {
					font-size: 0.36rem;
					color: #ff6d6d;
				}
			}
			.waveWarp {
				height: 1.3rem;
				background-color: #eceff3;
			}
		}
		.center {
			width: 100%;
			display: flex;
			justify-content: center;
			.center-left {
				width: 23%;
				height: 6.4rem;
				background: #eceff3;
				overflow: hidden;
				.warningTitle {
					width: 100%;
					height: 0.6rem;
					background-color: #127bf2;
					font-size: 0.24rem;
					font-family: Source Han Sans, Source Han Sans CN;
					font-weight: 700;
					color: #ffffff;
					line-height: 0.6rem;
					text-align: center;
				}
				.warningList {
					height: 5.6rem;
					padding: 5% 0 0 0;
					box-sizing: border-box;
					font-size: 0.18rem;
					overflow-x: hidden;
					overflow-y: auto;
					&:hover::-webkit-scrollbar {
						// display: block;
						visibility: initial;
						width: 0.05rem;
						height: 0.05rem;
					}
					&::-webkit-scrollbar {
						visibility: none;
						width: 0.05rem;
						height: 0.05rem;
					}

					&::-webkit-scrollbar-track {
						width: 0.05rem;
						background: transparent;
						-webkit-border-radius: 2em;
						-moz-border-radius: 2em;
						border-radius: 2em;
					}

					&::-webkit-scrollbar-thumb {
						background-color: #c7c9cd;
						background-clip: padding-box;
						min-height: 0.28rem;
						-webkit-border-radius: 2em;
						-moz-border-radius: 2em;
						border-radius: 2em;
					}

					&::-webkit-scrollbar-thumb:hover {
						background: #c9cdd4 content-box;
					}
					.warningItem {
						height: 0.28rem;
						border-bottom: 1px solid #ccc;
						p {
							width: 95%;
							overflow: hidden;
							white-space: nowrap;
							text-overflow: ellipsis;
						}
					}
				}
			}
			.center-right {
				width: 77%;
				height: 6.4rem;
				position: relative;
				background: #f8fafd;
				.audioList {
					height: 86%;
					overflow-x: hidden;
					overflow-y: auto;
					padding: 0.2rem 12%;
					box-sizing: border-box;
					&:hover::-webkit-scrollbar {
						// display: block;
						visibility: initial;
						width: 0.05rem;
						height: 0.05rem;
					}
					&::-webkit-scrollbar {
						visibility: none;
						width: 0.05rem;
						height: 0.05rem;
					}

					&::-webkit-scrollbar-track {
						width: 0.05rem;
						background: transparent;
						-webkit-border-radius: 2em;
						-moz-border-radius: 2em;
						border-radius: 2em;
					}

					&::-webkit-scrollbar-thumb {
						background-color: #c7c9cd;
						background-clip: padding-box;
						min-height: 0.28rem;
						-webkit-border-radius: 2em;
						-moz-border-radius: 2em;
						border-radius: 2em;
					}

					&::-webkit-scrollbar-thumb:hover {
						background: #c9cdd4 content-box;
						// background-color: rgb(134, 144, 156);
					}
					p {
						font-size: 0.18rem;
						line-height: 0.23rem;
					}
				}
				.dialog {
					color: #666666;
					position: absolute;
					left: 0.2rem;
					bottom: 0.2rem;
					font-size: 0.2rem;
					font-family: Source Han Sans, Source Han Sans CN;
					font-weight: 400;
					line-height: 0.28rem;
					padding: 0;
					&:hover {
						color: #127bf2;
					}
				}
				.tip {
					color: #acb7c4;
					font-size: 0.2rem;
					font-family: Source Han Sans, Source Han Sans CN;
					font-weight: 400;
					line-height: 0.28rem;
					position: absolute;
					left: 1.7rem;
					bottom: 0.2rem;
				}
				.iconfont {
					width: 0.4rem;
					height: 0.4rem;
					background-color: #127bf2;
					border-radius: 50%;
					position: absolute;
					right: 1.4rem;
					bottom: 0.2rem;
					color: #fff;
					text-align: center;
					line-height: 0.4rem;
					cursor: pointer;
				}
				.start,
				.stop {
					width: 1rem;
					height: 0.4rem;
					position: absolute;
					right: 0.2rem;
					bottom: 0.2rem;
					border: 0;
				}
				// .start {
				//   background-color: #486eed;
				// }
				// .stop {
				//   background-color: #eb6666;
				// }
			}
		}
	}
}
.selectAll {
	margin-bottom: 0.2rem;
	.el-select {
		width: 100%;
	}
}
.keyContent {
	border: 1px dashed #bcbcbc;
	border-radius: 0.04rem;
	padding: 0.05rem;
	height: 2.4rem;
	overflow-y: auto;
	text-align: left;
}
.el-tag {
	margin: 0.05rem;
}
.blueBtn {
	font-size: 0.14rem;
	color: #fff;
	background-color: #127bf2;
}
</style>
